
.page {
    --page-size: 33px;
    --prev-icon: "<";
    --next-icon: ">";
}


html[dir="rtl"] {
    .page {
        --prev-icon: ">";
        --next-icon: "<"; 
    }
}

/* 分页 */
.page {
    text-align: center;

    .pagination {
        display: inline-flex;
        padding-inline-start: 0;
        border-radius: 2px;

        .next:before {
            @apply iconfont;
            content: var(--next-icon);
            font-size: 14px;
        }

        .prev, .next {
            padding: 0;
            width: var(--page-size);
            min-width: var(--page-size);
        }

        .prev:before {
            @apply iconfont;
            content: var(--prev-icon);
            font-size: 14px;
        }

        & > li > a {
            float: left;


            min-width: var(--page-size);
            height: var(--page-size);
            line-height: var(--page-size);

            background: #F5F0F3;
            border-radius: 4px;

            font-size: 14px;
            /* color: #323233; */
            @apply text-title;
            text-align: center;

            margin-inline-start: 8px;
            padding: 0 8px;
        }

        & > li:first-child > a,
        & > li:first-child > span {
            margin-inline-start: 0;
        }

        & > li > a:hover, 
        & > li > a:focus,
        & > li > span:hover,
        & > li > span:focus {
            z-index: 2;
            /* color: $main-color; */
            @apply text-primary;
        }

        & > .active > a, 
        & > .active > a:hover, 
        & > .active > a:focus,
        & > .active > span,
        & > .active > span:hover,
        & > .active > span:focus {
            z-index: 3;
            color: #fff;
            /* background-color: $main-color; */
            /* border-color: $main-color; */
            @apply bg-primary;
            cursor: default; 
        }
    }

}

@font-face {
    font-family: 'iconfont';
    src: url('iconfont.woff2') format('woff2'),
         url('iconfont.woff') format('woff'),
         url('iconfont.ttf') format('truetype');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: inherit;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.image {
	position: relative;
    display: block;
    width: auto;
    height: auto;
    overflow: hidden;
}

.image::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
    width: 0;
    height: 0;
	background-color: transparent;
	background-size: cover;
	background-repeat: no-repeat;
}

.image.loading::after {
    width: 100%;
	height: 100%;
	background-image: url(placeholder.png);
}

.image.error::after {
    width: 100%;
	height: 100%;
 	background-image: url(failed.png) !important;
}

.image.error > img, .image.loading > img {
    padding: 10px;
}

.image > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

* {
    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }
    -ms-overflow-style: none;
    scrollbar-width: none
}




:root {
    --inset-inline-start: 0; 
    --inset-inline-end: 0; 
}

.fallbacks-start {
	left: var(--inset-inline-start) !important;
    right: auto !important;
}
.fallbacks-end {
    left: auto !important;
	right: var(--inset-inline-end) !important;
}

html[dir="rtl"] {
	.fallbacks-start {
        left: auto !important;
		right: var(--inset-inline-start) !important;
	}

	.fallbacks-end {
        right: auto !important;
		left: var(--inset-inline-end) !important;
	}
}