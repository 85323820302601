/* 
.page {
    --page-size: 33px;
    --prev-icon: "\e64c";
    --next-icon: "\e64b";
}

html[dir="rtl"] {
    .page {
		--prev-icon: "\e64b";
		--next-icon: "\e64c";
    }
} */

:root {
	--apk-no1: url(no1.svg);
	--apk-no2: url(no2.svg);
	--apk-no3: url(no3.svg);
}


.download {
    justify-content: center;
    background: linear-gradient(270deg, #FF0136 0%, #FF4662 100%);
    @apply flex items-center text-white;
}

.score {
	/* 这里用变量会让 -webkit-image-set 无法正确使用 */
	/* --image-url-1x: url(inf_rating5_grey.png); */
	/* --image-url-2x: url(inf_rating5_grey@2x.png); */
	--image-width: 50%;


	position: relative;
	background-image: image-set(
		url(inf_rating5_grey.png) 1x,
		url(inf_rating5_grey@2x.png) 2x
	);
	background-repeat: no-repeat;
}

.score::before {
	/* 这里用变量会让 -webkit-image-set 无法正确使用 */
	/* --image-url-1x: url(inf_rating5_org.png); */
	/* --image-url-2x: url(inf_rating5_org@2x.png); */

	content: '';
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	width: var(--image-width);
	height: 100%;
	background: image-set(
		url(inf_rating5_org.png) 1x,
		url(inf_rating5_org@2x.png) 2x
	);
	background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
	display: none;
}

.content {
	@apply flex flex-col lg:flex-row;

	& > .main {
		@apply flex-1 lg:self-start;
		& > .theiaStickySidebar {
			@apply space-y-10;

			&::after {
				display: none;
			}
		}
	}

	& > .aside  {
		@apply w-full lg:w-[300px] lg:min-w-[300px] lg:ms-8 xl:ms-10 lg:self-start mt-10 lg:mt-0;

		& > .theiaStickySidebar {
			@apply flex flex-col gap-y-10;

			&::after {
				display: none;
			}
		}
	}
}
.contact .icon{
	@apply fill-title group-hover:fill-primary;
}

/* .section {
	@apply bg-white rounded-3 lg:rounded-4 p-4 lg:p-5 xl:p-8;
}

.aside > .theiaStickySidebar > .section {
	@apply p-4 lg:p-5;
} */

.btn-more {
	@apply mx-auto w-[185px] h-10 rounded-1.5 border-title border flex items-center justify-center text-title font-bold hover:text-primary hover:border-primary
}

.btn-small {
	background-color: #EBEBED;
	@apply w-[68px] h-[28px] rounded-[14px] text-3 flex items-center justify-center text-title hover:bg-primary hover:text-white
}

.btn-download{
	background: linear-gradient(270deg, #FF0136 0%, #FF4662 100%);
	@apply text-white;
}

.icon-google {
	background-image: url(google.png);
}

.line-clamp-1 {
	word-break: break-all;
}