

.article-content {
	--article-title-size: 18px;
    --article-content-size: 16px;

    --article-title-height: 24px;
    --article-content-height: 26px;

	font-size: var(--article-content-size);
	line-height: var(--article-content-height);
	@apply text-content;

	h1,h2,h3,h4,h5,h6 {
		margin: 24px 0 20px;
		@apply text-title;
		font-size: var(--article-title-size);
		line-height: var(--article-title-height);
		font-weight: bold;
	}

	p {
		margin: 16px 0;
		word-wrap: break-word;
	}

	a {
		display: inline;
		@apply text-primary;
		text-decoration: underline;
	}

	.article-image,video,iframe {
		cursor: pointer;
		margin: 20px auto!important;
		max-width: 88%;
		object-fit: cover;
		object-position: center;
		display: flex;
	}

	img{
		width: auto;
		height: auto;
		max-width: 80%;
		margin-left: auto; 
		margin-right: auto; 
	}
}